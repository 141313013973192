import React from 'react'

import Page from '@layout'

const NotFoundPage = () => (
  <Page id='404'
    title='404: Not Found, Brah'
    headerVisible
    footerVisible
  >
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Page>
)

export default NotFoundPage
